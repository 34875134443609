<template>
    <div class="payment-wizard text-white"
        style="align-content: center; background-color: #292D7D; min-height: 100vh; padding: 20px;">
        <div class="back-button" @click="$router.go(-1)"
            style="position: absolute; top: 20px; left: 20px; cursor: pointer; display: flex; align-items: center; gap: 10px;">
            <i class="fa-solid fa-chevron-left text-white" style="font-size: 32px;"></i>
            <span style="font-size: 1.2rem; font-family: 'Montserrat', sans-serif;">TORNA INDIETRO</span>
        </div>
        <div class="user-info-nav" v-if="userFullName" @click="toggleMenu">
            <div class="user-avatar"></div>
            <span class="user-name">{{ userFullName.replace(/\b\w/g, c => c.toUpperCase()) }}</span>
            <div v-show="menuVisible" class="dropdown-menu">
                <a href="/account" class="menu-item">Account</a>
                <a href="/logout" class="menu-item">Logout</a>
            </div>
        </div>
        <div class="container text-center" v-if="productNotFound">
            <h1>
                <i class="fa-solid fa-xmark"></i>
            </h1>
            <h3 class="fw-bold">Impossibile trovare il prodotto.</h3>
            <router-link to="https://elitefitness.it/app">
                <button class="btn btn-custom">Torna all'App</button>
            </router-link>
        </div>
        <div v-if="selectedProduct" class="container-fluid">
            <div class="row">
                <!-- Left column with steps -->
                <div class="col-md-6 p-md-5">
                    <div class="step-container">
                        <!-- Step 1 -->
                        <div v-if="showVariationStep" class="step" :class="{ active: step === 1 }">
                            <div class="step-header">
                                <div class="step-circle">1</div>
                                <h2 class="step-title">SELEZIONA IL PIANO</h2>
                            </div>
                            <transition name="fade">
                                <div v-if="step === 1" class="step-content">
                                    <div class="duration-grid">
                                        <div v-for="variation in selectedProduct.variations"
                                            :key="variation.variationName" @click="selectVariation(variation)"
                                            :class="['duration-option', { selected: selectedVariationName === variation.variationName }]">
                                            <div class="duration-title">{{ variation.variationName }}</div>
                                            <div class="duration-price">{{ variation.price }}€</div>
                                            <div v-if="selectedVariationName === variation.variationName"
                                                class="checkmark">&#10004;</div>
                                        </div>
                                    </div>
                                    <div class="checkbox-container">
                                        <input type="checkbox" id="stopAndGo" class="custom-checkbox"
                                            v-model="requiresStopAndGo" />
                                        <div class="text-container">
                                            <label for="stopAndGo" class="custom-label">STOP & GO</label>
                                            <p class="custom-description">Il servizio Stop & Go consente di sospendere
                                                temporaneamente il tuo abbonamento.</p>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button @click="nextStep" class="btn btn-custom mt-4">Avanti</button>
                                    </div>
                                </div>
                            </transition>
                        </div>

                        <!-- Step 2 -->
                        <div v-if="!userLogged" class="step" :class="{ active: step === 2 }">
                            <div class="step-header">
                                <div class="step-circle">{{ showVariationStep ? '2' : '1' }}</div>
                                <h2 class="step-title">I TUOI DATI</h2>
                            </div>
                            <transition name="fade">
                                <div v-if="step === 2" class="step-content">
                                    <form @submit.prevent="nextStep">
                                        <router-link
                                            :to="{ name: 'login', query: { productId: selectedProductId, action: 'checkout' } }">
                                            <div class="btn white-container">
                                                Hai già un account? Accedi
                                            </div>
                                        </router-link>

                                        <div @click="toggleSection('personalInfo')"
                                            class="d-flex justify-content-between align-items-center mb-2">
                                            <h4>
                                                <b>1.</b> Informazioni Personali
                                            </h4>
                                            <i class="fa-solid fa-chevron-down"></i>
                                        </div>

                                        <div v-if="!collapsedSections.personalInfo">
                                            <div class="form-group">
                                                <input v-model="user.firstName" name="firstName" type="text" placeholder="Nome"
                                                    class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.lastName" name="lastName" type="text" placeholder="Cognome"
                                                    class="form-control" required />
                                            </div>
                                            <div class="form-group" style="margin-bottom: 15px;">
                                                <Datepicker name="dateOfBirth" v-model="user.dateOfBirth" :format="format"
                                                    :placeholder="'Data di nascita'" :enable-time-picker="false"
                                                    :auto-apply="true" :teleport="true" input-class-name="form-control"
                                                    :preview-format="previewFormat"
                                                    @update:model-value="updateDateOfBirth">
                                                    <template #action-select>
                                                        <span class="action-select">Seleziona</span>
                                                    </template>
                                                </Datepicker>
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.placeOfBirth" name="placeOfBirth" type="text"
                                                    placeholder="Luogo di nascita" class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.taxCode" name="taxCode" type="text" placeholder="Codice Fiscale"
                                                    class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <GenderSelect v-model="user.gender"></GenderSelect>
                                            </div>
                                        </div>
                                        <div @click="toggleSection('contactInfo')"
                                            class="d-flex justify-content-between align-items-center mb-2 mt-5">
                                            <h4><b>2.</b> Recapiti</h4>
                                            <i class="fa-solid fa-chevron-down"></i>
                                        </div>
                                        <div v-if="!collapsedSections.contactInfo">
                                            <div class="form-group">
                                                <input v-model="user.address" type="text" placeholder="Indirizzo"
                                                    class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.city" type="text" placeholder="Città"
                                                    class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.postalCode" type="text" placeholder="CAP"
                                                    class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.province" type="text" placeholder="Provincia"
                                                    class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.phoneNumber" type="text" placeholder="Telefono"
                                                    class="form-control" />
                                            </div>
                                        </div>
                                        <div @click="toggleSection('accountInfo')"
                                            class="d-flex justify-content-between align-items-center mb-2 mt-5">
                                            <h4><b>3.</b> Crea un account</h4>
                                            <i class="fa-solid fa-chevron-down"></i>
                                        </div>
                                        <div v-if="!collapsedSections.accountInfo">
                                            <div class="form-group">
                                                <input v-model="user.email" type="email" placeholder="Email"
                                                    class="form-control" @blur="checkEmailExists"
                                                    :class="{ 'is-invalid': emailError }" required />
                                                <span v-if="emailError" class="text-danger">{{ emailError }}</span>
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.password"
                                                    :type="showPassword ? 'text' : 'password'" placeholder="Password"
                                                    class="form-control" @blur="checkPassword"
                                                    :class="{ 'is-invalid': passwordError }" required />
                                                <span v-if="passwordError" class="text-danger">{{ passwordError
                                                    }}</span>
                                                <button type="button" class="toggle-password"
                                                    @click="togglePasswordVisibility">
                                                    {{ showPassword ? 'Nascondi' : 'Mostra' }}
                                                </button>
                                            </div>
                                        </div>
                                        <p class="terms text-center">
                                            Accetto i termini e le <a class="text-white"
                                                href="https://elitefitness.it/condizioni-generali-contratto">condizioni
                                                generali di contratto</a>.<br>
                                            Confermo di aver preso visione dell'informativa relativa al <a
                                                class="text-white"
                                                href="https://elitefitness.it/diritto-di-recesso">diritto di
                                                recesso</a> e dell'informativa sulla <a class="text-white"
                                                href="https://elitefitness.it/privacy">Privacy</a>.
                                        </p>
                                        <div class="text-center mt-4">
                                            <button type="submit" class="btn btn-custom" :disabled="isLoading"
                                                :class="{ 'loading': isLoading }">
                                                <span v-if="!isLoading">
                                                    {{ collapsedSections.accountInfo === false ? 'Registrati' : 'Avanti' }}
                                                </span>
                                                <span v-else>
                                                    <span class="loading-dots">
                                                        <span>.</span>
                                                        <span>.</span>
                                                        <span>.</span>
                                                    </span>
                                                </span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </transition>
                        </div>

                        <!-- Step 3 -->
                        <div class="step" :class="{ active: step === 3 }">
                            <div class="step-header">
                                <div class="step-circle">{{ (showVariationStep && !userLogged) ? '3' : '2' }}</div>
                                <h2 class="step-title">PAGAMENTO</h2>
                            </div>
                            <transition name="fade">
                                <div v-if="step === 3" class="step-content">
                                    <div class="payment-method-section">
                                        <h4>Metodo di pagamento</h4>
                                        <div class="payment-grid">
                                            <div v-for="method in paymentMethods" :key="method.value"
                                                @click="selectPaymentMethod(method)"
                                                :class="['payment-option', { selected: selectedPayment === method.value }]">
                                                <div class="payment-title">{{ method.label }}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Messaggio per contanti -->
                                    <div v-if="selectedPayment === 'CASH'" class="payment-info">
                                        Disponibile dalle 9:00 alle 13:00
                                    </div>

                                    <!-- Stripe Credit Card Payment -->
                                    <div v-else-if="selectedPayment === 'CARD'">
                                        <h4>Carta di credito</h4>
                                        <div id="card-element" class="form-control mb-3"></div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <!-- Right column with overview -->
                <div class="col-md-6" style="border-left: 3px solid; border-color: white;">
                    <div class="overview p-5">
                        <h2 class="fw-bold">PANORAMICA</h2>

                        <p>Stai acquistando <strong class="highlight">{{ selectedProduct.name }}</strong></p>

                        <!-- Variation Overview -->
                        <div v-if="selectedVariationName">

                            <div class="row">
                                <div class="col">
                                    <ul class="list-unstyled">
                                        <li>
                                            Durata:
                                        </li>
                                        <li>
                                            Quota di abbonamento:
                                        </li>
                                        <li>
                                            Rinnovo:
                                        </li>
                                    </ul>
                                </div>
                                <div class="col">
                                    <ul class="list-unstyled text-end">
                                        <li>
                                            <span class="highlight">{{ selectedVariationName || 'N/A' }}</span>
                                        </li>
                                        <li>
                                            <span class="highlight">{{ (finalPrice - discountAmount).toFixed(2) || 'N/A'
                                                }}€</span>
                                        </li>
                                        <li>
                                            <span class="highlight">fra {{ renewalPeriod || 'N/A' }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <ul class="list-unstyled">
                                        <li v-if="requiresRegistration">Iscrizione ed assicurazione:</li>
                                        <li v-if="requiresMembership">Tesseramento:</li>
                                        <li v-if="requiresStopAndGo">Stop & Go:</li>
                                    </ul>
                                </div>
                                <div class="col">
                                    <ul class="list-unstyled text-end">
                                        <li v-if="requiresRegistration">
                                            <span class="highlight">{{ registrationProduct?.price.toFixed(2) }}€</span>
                                        </li>
                                        <li v-if="requiresMembership">
                                            <span class="highlight">{{ membershipProduct?.price.toFixed(2) }}€</span>
                                        </li>
                                        <li v-if="requiresStopAndGo">
                                            <span class="highlight">{{ selectedStopAndGoVariation?.price.toFixed(2)
                                                }}€</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <h4 class="mt-5 fw-bold">TOTALE</h4>
                                </div>
                                <div class="col">
                                    <h4 class="mt-5 fw-bold text-end">{{ (selectedVariationPrice -
                                        discountAmount).toFixed(2) || 'N/A' }}€</h4>
                                </div>
                            </div>

                        </div>

                        <!-- Product Overview -->
                        <div v-if="!showVariationStep">
                            <div class="row">
                                <div class="col">
                                    <h4 class="mt-5 fw-bold">TOTALE</h4>
                                </div>
                                <div class="col">
                                    <h4 class="mt-5 fw-bold text-end">{{ (selectedVariationPrice -
                                        discountAmount).toFixed(2) || 'N/A' }}€</h4>
                                </div>
                            </div>

                        </div>

                        <p class="terms">
                            Accetto i termini e le <a href="#">condizioni generali di contratto</a>.<br>
                            Confermo di aver preso visione dell'informativa relativa al <a href="#">diritto di
                                recesso</a> e dell'informativa sulla <a href="#">Privacy</a>.
                        </p>
                        <div v-if="selectedVariationName || !showVariationStep" class="mt-3">
                            <div class="input-group mb-3">
                                <input type="text" v-model="discountCode"
                                    class="form-control discount custom-placeholder"
                                    placeholder="INSERISCI IL CODICE SCONTO" aria-label="Codice Sconto"
                                    aria-describedby="button-addon2">
                                <button class="btn btn-danger rounded-0" type="button"
                                    @click="applyDiscount">APPLICA</button>
                            </div>
                        </div>
                        <div class="text-center mt-4">
                            <button :disabled="!isPaymentReady" @click="handlePayment"
                                class="btn btn-custom complete-payment-btn text-nowrap">
                                COMPLETA IL PAGAMENTO
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import GenderSelect from '@/components/GenderSelect.vue';
import Datepicker from '@vuepic/vue-datepicker'
import { useRoute } from 'vue-router';
import { onMounted } from 'vue';

export default {
    components: {
        GenderSelect,
        Datepicker
    },
    setup() {
        const route = useRoute();

        onMounted(() => {
            console.log("Route object after mount:", route);
            console.log("Product ID from URL after mount:", route.query?.productId);
        });

        return {};
    },
    data() {
        return {
            isLoading: false,
            menuVisible: false,
            userLogged: false,
            userEmail: null,
            userToken: null,
            userFullName: null,

            step: 1,  // Step iniziale
            selectedVariationName: null,  // Variazione selezionata
            selectedProductId: null,  // Imposta l'id del prodotto specifico qui
            selectedProduct: null,

            productNotFound: false,

            hasVariations: false,  // Indica se il prodotto ha variazioni
            showVariationStep: false,  // Controlla se lo step delle variazioni deve essere mostrato

            user: {
                email: '',
                password: '',
                firstName: '',
                lastName: '',
                dateOfBirth: null,
                placeOfBirth: '',
                taxCode: '',
                gender: '',
                address: '',
                city: '',
                postalCode: '',
                province: '',
                phoneNumber: '',
                mobileNumber: ''
            },
            stripe: null,
            elements: null,
            cardElement: null,
            paymentRequestButton: null,
            paymentRequest: null,
            isPaymentReady: false,

            discountCode: '',  // Codice sconto inserito dall'utente
            discountAmount: 0,  // Importo dello sconto
            discountError: null,  // Messaggio di errore per il codice sconto
            finalPrice: 0,  // Prezzo finale dopo lo sconto

            emailError: null,

            requiresRegistration: true,
            requiresMembership: true,
            requiresStopAndGo: false,
            registrationProduct: null, // Prodotto di iscrizione e assicurazione
            membershipProduct: null, // Prodotto di tesseramento
            stopngoProduct: null, // Prodotto di Stop & Go

            selectedStopAndGoVariation: null,

            paymentMethods: [
                { label: "Carta", value: "CARD" },
                { label: "Contanti", value: "CASH" },
            ],
            selectedPayment: "CARD", // Default su Carta
            format: 'dd/MM/yyyy',
            previewFormat: (date) => {
                if (!date) return ''
                const day = date.getDate().toString().padStart(2, '0')
                const month = (date.getMonth() + 1).toString().padStart(2, '0')
                const year = date.getFullYear()
                return `${day}/${month}/${year}`
            },
            showPassword: false,
            passwordError: null,

            // Registration Steps
            collapsedSections: {
                personalInfo: false,
                contactInfo: true,
                accountInfo: true,
            },
        };
    },
    async created() {
        try {
            // Inizializza Stripe
            this.stripe = await loadStripe('pk_live_51OtUFJEHkZfMednN6BucyYkJOmLNU59eic1YVCrbEjbKSSmWJwOALrY66BipFuxyjCzlJSXAly15TqU8YO6LPCm400tp8TIrsz');
            if (!this.stripe) {
                throw new Error('Stripe failed to initialize');
            }

            // Verifica se l'utente è loggato
            const email = localStorage.getItem('userEmail');
            const token = localStorage.getItem('userToken');

            if (email && token) {
                this.userLogged = true;
                this.userEmail = email;
                this.userToken = token;

                try {
                    // Recupera il profilo utente
                    const response = await fetch('https://elitefitness.utopialabs.it/api/users/profile', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (!response.ok) {
                        this.userLogged = false;
                        this.userEmail = null;
                        this.userToken = null;
                        localStorage.removeItem('userEmail');
                        localStorage.removeItem('userToken');
                        localStorage.removeItem('tokenExpiration');
                        console.error('Errore nel recupero del profilo utente');
                        this.$router.go();
                        return;
                    }

                    const userData = await response.json();
                    this.userFullName = userData.personalInfo.fullName.replace(/\b\w/g, c => c.toUpperCase());
                } catch (error) {
                    console.error('Errore durante la richiesta al server:', error);
                }
            }

            const productIdFromUrl = this.$route.query?.productId;

            let productResponse;
            if (productIdFromUrl) {
                productResponse = await fetch(`https://elitefitness.utopialabs.it/api/products/${productIdFromUrl}`);
                if (productResponse.ok) {
                    const product = await productResponse.json();
                    this.selectedProduct = product;
                    this.selectedProductId = product.id;
                } else {
                    this.productNotFound = true;
                }
            } else {
                // Continua con il funzionamento attuale
                productResponse = await fetch('https://elitefitness.utopialabs.it/api/products');
                if (productResponse.ok) {
                    const products = await productResponse.json();
                    this.selectedProduct = products.find((p) => p.name.includes("Body Building"));
                    if (this.selectedProduct)
                        this.selectedProductId = this.selectedProduct.id;
                    else this.productNotFound = true;
                }
            }

            // Controlla se il prodotto ha variazioni
            if ((this.selectedProduct.type === 'GYM' || this.selectedProduct.type === 'COURSE') && this.selectedProduct.variations && this.selectedProduct.variations.length > 0) {
                this.hasVariations = true;
                this.showVariationStep = true;
            } else {
                // Non ci sono variazioni o il prodotto non è di tipo GYM o COURSE
                this.hasVariations = false;
                this.showVariationStep = false;
                this.finalPrice = this.selectedProduct.price;  // Usa il prezzo del prodotto
                this.step = 2; // Salta direttamente allo step successivo
            }

            if (this.userLogged) {
                // Fetch stato dei constraints utente solo se loggato
                const constraintsResponse = await fetch('https://elitefitness.utopialabs.it/api/users/constraints', {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                    },
                });
                if (constraintsResponse.ok) {
                    const constraints = await constraintsResponse.json();
                    this.requiresRegistration = !constraints.registrationInsurance;
                    this.requiresMembership = !constraints.membershipCard;

                    if (this.requiresRegistration) {
                        const registrationProductResponse = await fetch('https://elitefitness.utopialabs.it/api/products/672cdc7b078175c5932ae517');
                        if (registrationProductResponse.ok) {
                            this.registrationProduct = await registrationProductResponse.json();
                        }
                    }

                    if (this.requiresMembership) {
                        const membershipProductResponse = await fetch('https://elitefitness.utopialabs.it/api/products/672cdc7b078175c5932ae518');
                        if (membershipProductResponse.ok) {
                            this.membershipProduct = await membershipProductResponse.json();
                        }
                    }

                    const stopngoProductResponse = await fetch('https://elitefitness.utopialabs.it/api/products/67404798180f654c79a1286b');
                    if (stopngoProductResponse.ok) {
                        this.stopngoProduct = await stopngoProductResponse.json();
                    }

                } else {
                    console.error('Failed to fetch user constraints');
                }
            } else {
                // Utente non loggato: imposta di default registrazione e tesseramento
                this.requiresRegistration = true;
                this.requiresMembership = true;

                // Recupera i prodotti di registrazione e tesseramento
                const registrationProductResponse = await fetch('https://elitefitness.utopialabs.it/api/products/672cdc7b078175c5932ae517');
                if (registrationProductResponse.ok) {
                    this.registrationProduct = await registrationProductResponse.json();
                }

                const membershipProductResponse = await fetch('https://elitefitness.utopialabs.it/api/products/672cdc7b078175c5932ae518');
                if (membershipProductResponse.ok) {
                    this.membershipProduct = await membershipProductResponse.json();
                }

                const stopngoProductResponse = await fetch('https://elitefitness.utopialabs.it/api/products/67404798180f654c79a1286b');
                if (stopngoProductResponse.ok) {
                    this.stopngoProduct = await stopngoProductResponse.json();
                }


            }

        } catch (error) {
            console.error('Error initializing PaymentComponent:', error);
        }
    },


    computed: {
        selectedVariationPrice() {
            if (this.selectedProduct?.variations) {
                // Trova la variazione selezionata del prodotto principale
                const variation = this.selectedProduct.variations.find(
                    variation => variation.variationName === this.selectedVariationName
                );

                // Calcola le fee di registrazione e membership
                const regFee = this.requiresRegistration ? this.registrationProduct.price : 0;
                const membFee = this.requiresMembership ? this.membershipProduct.price : 0;

                // Calcola il prezzo dello Stop & Go, se selezionato
                const stopAndGoPrice = this.requiresStopAndGo ? (this.selectedStopAndGoVariation?.price || 0) : 0;

                // Ritorna il prezzo totale
                return variation ? (variation.price + regFee + membFee + stopAndGoPrice) : null;
            } else {
                // Nessuna variazione, restituisci solo il prezzo del prodotto principale
                return this.selectedProduct.price;
            }
        },
        renewalPeriod() {
            const variation = this.selectedProduct.variations.find(variation => variation.variationName === this.selectedVariationName);
            return variation ? variation.attributes.duration + ' giorni' : null;
        }
    },
    methods: {
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        checkPassword() {
            if (!this.validatePassword(this.user.password)) {
                this.passwordError = "La password deve essere lunga tra 8 e 100 caratteri e contenere almeno un numero, una lettera maiuscola, una lettera minuscola, un carattere speciale e nessuno spazio.";
            } else {
                this.passwordError = null;
            }
        },
        validatePassword(password) {
            const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
            return passwordPattern.test(password);
        },
        toggleMenu() {
            this.menuVisible = !this.menuVisible;
        },
        clearUserData() {
            localStorage.removeItem('user');
        },
        saveUserData() {
            localStorage.setItem('user', JSON.stringify(this.user));
        },
        async checkEmailExists() {
            try {
                if (!this.user.email) {
                    this.emailError = 'Inserisci un\'email valida.';
                    return;
                }

                const response = await fetch(`https://elitefitness.utopialabs.it/api/users/exists?email=${encodeURIComponent(this.user.email)}`);

                if (!response.ok) {
                    throw new Error('Errore nella verifica dell\'email');
                }

                const result = await response.json();

                // Verifica il risultato: se l'email esiste, mostra un errore
                if (result.exists) {
                    this.emailError = 'Questa email è già registrata.';
                } else {
                    this.emailError = null; // Nessun errore, email non esistente
                }
            } catch (error) {
                console.error('Errore nella verifica dell\'email:', error);
                this.emailError = 'Impossibile verificare l\'email. Riprova più tardi.';
            }
        },
        async applyDiscount() {
            if (!this.discountCode.trim()) {
                this.discountError = 'Inserisci un codice sconto valido.';
                return;
            }

            try {
                // Effettua la chiamata API per verificare il codice sconto
                const response = await fetch(`https://elitefitness.utopialabs.it/api/promo/${this.discountCode}`);

                if (!response.ok) {
                    throw new Error('Codice sconto non valido.');
                }

                const promo = await response.json();

                // Verifica se il codice sconto è attivo e valido
                const now = Date.now();
                if (!promo.active || now < promo.startDate || now > promo.endDate || promo.currentUsage >= promo.usageLimit) {
                    this.discountError = 'Codice sconto non valido o scaduto.';
                    return;
                }

                // Calcola lo sconto
                if (promo.percentage) {
                    this.discountAmount = (promo.discountAmount / 100) * this.selectedVariationPrice;
                } else {
                    this.discountAmount = promo.discountAmount;
                }

                // Aggiorna il prezzo finale
                this.finalPrice = this.selectedVariationPrice - this.discountAmount;
                this.discountError = null;  // Rimuovi eventuali errori
            } catch (error) {
                this.discountError = error.message;
                this.discountAmount = 0;  // Reset dello sconto in caso di errore
                this.finalPrice = this.selectedVariationPrice;  // Reset del prezzo finale
            }
        },
        toggleSection(section) {
            Object.keys(this.collapsedSections).forEach((key) => {
                this.collapsedSections[key] = true;
            });

            this.collapsedSections[section] = !this.collapsedSections[section];
        },
        selectVariation(variation) {
            this.selectedVariationName = variation.variationName;
            this.finalPrice = variation.price;

            // Trova e assegna la variazione dello Stop & Go corrispondente
            if (this.stopngoProduct?.variations) {
                console.log("Trova e assegna la variazione dello Stop & Go corrispondente")
                this.selectedStopAndGoVariation = this.stopngoProduct.variations.find(
                    stopGoVariation => stopGoVariation.variationName === this.selectedVariationName
                );
            }
        },
        nextStep() {
            // Step 1: Selezione del piano
            if (this.step === 1) {
                if (!this.selectedVariationName) {
                    alert("Seleziona un piano per continuare.");
                    return;
                }
                this.step += 1; // Passa allo step successivo
                return;
            }

            // Step 2: Inserimento dei dati personali
            if (this.step === 2) {
                console.log("Step 2")
                // Integrazione della logica di nextRegistrationStep
                if (!this.collapsedSections.personalInfo) {
                    this.collapsedSections.personalInfo = true;
                    this.collapsedSections.contactInfo = false;
                } else if (!this.collapsedSections.contactInfo) {
                    this.collapsedSections.contactInfo = true;
                    this.collapsedSections.accountInfo = false;
                } else if (!this.collapsedSections.accountInfo) {
                    // Quando tutte le sezioni sono complete, esegui la registrazione
                    if (!this.userLogged) {
                        this.register()
                            .then(() => {
                                // Dopo la registrazione, passa allo step successivo
                                this.step += 1;
                                if (this.step === 3) {
                                    this.initializeStripeElements();
                                    this.isPaymentReady = false; // Disabilita temporaneamente il pulsante di pagamento
                                }
                            })
                            .catch((error) => {
                                console.error("Errore durante la registrazione:", error);
                                alert("Si è verificato un problema durante la registrazione. Riprova.");
                            });
                        return; // Non continuare fino al completamento della registrazione
                    }

                    // Se l'utente è loggato, passa direttamente allo step successivo
                    this.step += 1;
                    return;
                }
                return; // Gestione completa delle sezioni interne di dati
            }

            // Step 3: Pagamento
            if (this.step === 3) {
                this.initializeStripeElements();
                this.isPaymentReady = true; // Abilita il pulsante di pagamento
            }
        },
        initializeStripeElements() {
            console.log("initializeStripeElements")
            this.$nextTick(() => {
                if (!this.stripe) {
                    console.error("Stripe is not initialized yet.");
                    return;
                }

                if (!this.elements) {
                    this.elements = this.stripe.elements();
                }

                if (!this.cardElement) {
                    // Definisci lo stile per l'elemento della carta
                    const style = {
                        base: {
                            color: 'white',            // Testo bianco
                            fontSize: '20px',          // Testo più grande
                            '::placeholder': {
                                color: 'rgba(255, 255, 255, 0.7)',  // Placeholder bianco, ma più trasparente
                            },
                            textAlign: 'center',       // Testo centrato
                            fontFamily: 'Montserrat, sans-serif', // Imposta il font
                            iconColor: 'white',        // Colore delle icone
                        },
                        invalid: {
                            color: '#ff4d4f',           // Colore rosso per gli input non validi
                            iconColor: '#ff4d4f',
                        },
                    };

                    // Crea e monta l'elemento della carta con lo stile
                    this.cardElement = this.elements.create('card', { style });
                    this.cardElement.mount('#card-element');
                }

                // Abilita il pulsante di pagamento dopo che i dati della carta sono pronti
                this.isPaymentReady = true;
            });
        },
        async handleCardPayment() {
            try {
                if (!this.stripe || !this.cardElement) {
                    alert("Stripe or card element is not ready.");
                    return;
                }

                const selectedVariation = this.selectedProduct.variations.find(variation => variation.variationName === this.selectedVariationName);
                if (!selectedVariation) {
                    console.error("Selected variation not found");
                    return;
                }

                // Determina i prodotti di upsell necessari
                const upsells = [];
                if (this.requiresRegistration && this.registrationProduct) {
                    upsells.push(this.registrationProduct.id);
                }
                if (this.requiresMembership && this.membershipProduct) {
                    upsells.push(this.membershipProduct.id);
                }

                const paymentRequest = {
                    productId: this.selectedProductId,
                    variationName: this.selectedVariationName,
                    newUser: this.userLogged ? null : this.user,
                    promoCode: this.discountCode,
                    registerUser: !this.userLogged,
                    email: this.userEmail,
                    token: this.userToken,
                    upsells, // Aggiungi i prodotti di upsell
                };

                const response = await fetch('/api/payment/create', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(paymentRequest),
                });

                if (!response.ok) {
                    throw new Error('Failed to create payment intent');
                }

                const { client_secret } = await response.json();

                const { error, paymentIntent } = await this.stripe.confirmCardPayment(client_secret, {
                    payment_method: {
                        card: this.cardElement,
                        billing_details: {
                            name: this.user.personalInfo.firstName + ' ' + this.user.personalInfo.lastName,
                            email: this.user.email,
                        },
                    },
                });

                if (error) {
                    alert(error.message);
                } else if (paymentIntent.status === 'succeeded') {
                    this.$router.push('/success');
                }
            } catch (error) {
                console.error(error);
                alert('An error occurred during payment.');
            }
        },
        isUserDataValid() {
            return this.user.email && this.user.password && this.user.firstName && this.user.lastName;
        },
        selectPaymentMethod(method) {
            this.selectedPayment = method.value;

            // Aggiorna la logica del pulsante
            if (method.value === "CARD") {
                this.initializeStripeElements(); // Configura Stripe
                this.isPaymentReady = true;
            } else {
                this.isPaymentReady = true; // Anche per contanti abilitiamo il pulsante
            }
        },
        handlePayment() {
            if (this.selectedPayment === "CARD") {
                this.handleCardPayment(); // Logica per pagamento con carta
            } else if (this.selectedPayment === "CASH") {
                this.handleCashPayment(); // Logica per pagamento in contanti
            }
        },
        handleCashPayment() {
            /*
            const selectedVariation = this.selectedProduct.variations
                ? this.selectedProduct.variations.find(variation => variation.variationName === this.selectedVariationName)
                : null;
            */
            // Determina i prodotti di upsell necessari
            const upsells = [];
            if (this.requiresRegistration && this.registrationProduct) {
                upsells.push(this.registrationProduct.id);
            }
            if (this.requiresMembership && this.membershipProduct) {
                upsells.push(this.membershipProduct.id);
            }
            if (this.requiresStopAndGo && this.stopngoProduct) {
                upsells.push(this.stopngoProduct.id)
            }

            const paymentRequest = {
                productId: this.selectedProductId,
                variationName: this.selectedVariationName,
                newUser: this.userLogged ? null : this.user,
                promoCode: this.discountCode,
                registerUser: !this.userLogged,
                email: this.userEmail,
                token: this.userToken,
                upsells, // Aggiungi i prodotti di upsell
            };

            fetch('https://elitefitness.utopialabs.it/api/payment/cash/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.userToken}`,
                },
                body: JSON.stringify(paymentRequest),
            })
                .then(response => {
                    if (!response.ok) {
                        return response.text().then(errorText => {
                            throw new Error(`Errore durante il pagamento in contanti: ${errorText}`);
                        });
                    }
                    return response.text();
                })
                .then(result => {
                    console.log(result);
                    alert('Pagamento in contanti registrato con successo!');

                    // Reindirizza alla pagina di successo
                    this.$router.push('/success');
                })
                .catch(error => {
                    console.error(error);
                    alert('Si è verificato un errore durante il pagamento in contanti.');
                });
        },
        async register() {
            if (this.emailError) {
                alert("L'email non è valida o è già in uso.");
                throw new Error("Errore di validazione email");
            }

            if (this.passwordError) {
                alert("La password non soddisfa i requisiti minimi di sicurezza.");
                throw new Error("Errore di validazione password");
            }

            this.isLoading = true; // Attiva il caricamento
            try {
                // Registrazione utente
                const response = await fetch('https://elitefitness.utopialabs.it/api/auth/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.user),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Errore durante la registrazione');
                }

                console.log('Registrazione completata.');

                // Login automatico
                const loginResponse = await fetch('https://elitefitness.utopialabs.it/api/auth/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: this.user.email,
                        password: this.user.password,
                    }),
                });

                if (!loginResponse.ok) {
                    const loginErrorData = await loginResponse.json();
                    throw new Error(loginErrorData.message || 'Errore durante il login automatico');
                }

                const loginData = await loginResponse.json();
                console.log('Login completato:', loginData);

                this.userEmail = this.user.email;
                this.userToken = loginData.token;
                // Salva il token e l'email dell'utente
                localStorage.setItem('userEmail', loginData.email);
                localStorage.setItem('userToken', loginData.token);

                // Recupera il profilo utente
                const profileResponse = await fetch('https://elitefitness.utopialabs.it/api/users/profile', {
                    headers: {
                        Authorization: `Bearer ${loginData.token}`,
                    },
                });

                if (!profileResponse.ok) {
                    throw new Error('Errore nel recupero del profilo utente');
                }

                const userProfile = await profileResponse.json();
                console.log('Profilo utente recuperato:', userProfile);

                // Aggiorna lo stato utente
                this.userLogged = true;
                this.userFullName = userProfile.personalInfo.fullName.replace(/\b\w/g, (c) => c.toUpperCase());

                // Restituisci il token per eventuali chiamate successive
                return loginData.token;

            } catch (error) {
                console.error('Errore durante la registrazione, login o recupero del profilo:', error);
                alert(error.message || 'Si è verificato un errore. Riprova più tardi.');
                throw error;
            } finally {
                this.isLoading = false; // Disattiva il caricamento
            }
        },
        updateDateOfBirth(date) {
            console.log('Data di nascita aggiornata:', date)
        },
    }
};
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}

h2,
h3,
h4 {
    font-family: 'Montserrat', sans-serif;
}

.step-header {
    display: flex;
    align-items: center;
    gap: 15px;
    text-align: left;
    margin-bottom: 40px;
}

.step-circle {
    font-family: 'Montserrat', sans-serif;
    width: 50px;
    height: 50px;
    background-color: white;
    color: #e83539;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
}

.step-title {
    color: white;
    font-size: 2rem;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
}

.step-content {
    margin-bottom: 40px;
    margin-left: 0;
    /* Nessun margine a sinistra su dispositivi piccoli */
}

@media (min-width: 768px) {
    .step-content {
        margin-left: 65px;
        /* Applica margin-left solo su tablet e dispositivi più grandi */
    }
}


.duration-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

@media (max-width: 767px) {
    .duration-grid {
        grid-template-columns: 1fr;
    }
}

.duration-option {
    font-family: 'Montserrat', sans-serif;
    background-color: #2e348c;
    border: 2px solid white;
    padding: 20px;
    text-align: center;
    color: white;
    cursor: pointer;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    position: relative;
}

/** Mobile */
@media (max-width: 767px) {
    .duration-option {
        width: 100%;
        padding: 5px;
    }
}

.duration-option.selected {
    border-color: #e83539;
    background-color: #292D7D;
}

.duration-title {
    font-size: 1.2rem;
    /* Font-size su dispositivi mobili */
    font-weight: 600;
}

@media (min-width: 768px) {
    .duration-title {
        font-size: 1.5rem;
    }
}

.duration-price {
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 10px;
}

.checkmark {
    position: absolute;
    top: -10px;
    left: -10px;
    background-color: #e83539;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.overview {
    padding: 20px;
}

h4 {
    margin: 0px;
    padding: 0px;
}

h2,
h3,
p,
ul {
    color: white;
}

ul {
    padding: 0;
}

.terms {
    font-size: 0.9rem;
    text-align: center;
    margin: 20px 0;
}

.terms a {
    color: white;
    text-decoration: underline;
}

.complete-payment-btn {
    background-color: #b2003b;
    width: 100%;
    max-width: 100% !important;
    margin: 20px auto;
    padding: 10px;
    border: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
}

.complete-payment-btn:disabled {
    background-color: #e83539;
    cursor: not-allowed;
}

.btn-custom {
    font-family: 'Montserrat', sans-serif;
    background-color: #e83539;
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}

.btn-custom:hover {
    background-color: #cc2e31;
}

.highlight {
    font-weight: 600;
    color: white;
    text-decoration: underline;
}

form input,
#card-element {
    color: black;
    margin-bottom: 15px;
    height: 60px;
    padding: 0 10px;
    border-radius: 0;
}

form select {
    color: white;
    margin-bottom: 15px;
    height: 60px;
    padding: 0 10px;
    border-radius: 0;
}

select::after {
    color: white;
}

.form-select {
    appearance: none;
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid;
    border-color: white;
    color: white;
    border-radius: 0px;
    width: 100%;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23FFFFFF" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 5.646a.5.5 0 0 1 .708 0L8 11.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
    background-size: 24px;
}

.form-control {
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid;
    border-color: white;
    color: white;
}

.form-control::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.discount {
    color: rgb(19, 19, 19) !important;
    background-color: white;
    border-radius: 0px;
    height: 45px;
}

.custom-placeholder::-webkit-input-placeholder {
    /* Chrome, Safari, Edge */
    color: #232323;
}

.custom-placeholder:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #232323;
}

.custom-placeholder::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #232323;
}

.custom-placeholder::placeholder {
    /* Standard */
    color: #232323;
}


.white-container {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.207);
    border-radius: 0px;
    color: #ffffff;
    padding: 10px;
    margin-bottom: 25px;
}

.white-container:hover {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.352);
    border-radius: 0px;
    color: #ffffff;
    padding: 10px;
    margin-bottom: 25px;
}

/* Stili per il layout di pagamento */
.payment-method-section {
    margin-bottom: 20px;
}

.payment-grid {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.payment-option {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    flex-grow: 1;
    font-family: "Montserrat", sans-serif;
}

.payment-option.selected {
    border-color: #e83539;
}

.payment-info {
    margin-top: 10px;
    font-size: 1.2rem;
    color: white;
    background-color: #e83539;
    padding: 15px;
}

.complete-payment-btn {
    background-color: #b2003b;
    width: 100%;
    margin: 20px auto;
    padding: 10px;
    border: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
}

.complete-payment-btn:disabled {
    background-color: #e83539;
    cursor: not-allowed;
}

.checkbox-container {
    display: flex;
    align-items: center;
    /* Allinea verticalmente al centro */
    gap: 15px;
    /* Spazio tra checkbox e testo */
    font-family: 'Montserrat', sans-serif;
    padding: 20px;
    border-radius: 0px;
    border: 2px solid white;
    margin-top: 20px;
}

.custom-checkbox {
    appearance: none;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    background-color: #2e348c;
    border: 2px solid rgba(255, 255, 255, 0.144) !important;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-checkbox:checked {
    background-color: #e83539;
}

.text-container {
    display: flex;
    flex-direction: column;
    /* Dispone il titolo sopra e la descrizione sotto */
}

.custom-label {
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
}

.custom-description {
    margin-top: 5px;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
    /* Colore bianco trasparente */
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 0px !important;
}

@media (max-width: 767px) {
    .back-button {
        display: none !important;
    }

    .user-info-nav {
        display: none !important;
    }
}

.back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s ease;
    margin: 20px;
    padding-bottom: 10px;
    /* Assicurati che sia visibile sopra gli altri elementi */
}

.back-button i {
    font-size: 24px;
    color: white;
    transition: transform 0.2s ease;
}

.back-button:hover {
    transform: scale(1.05);
    border-bottom: 2px solid #e83539;
    /* Leggera animazione al passaggio del mouse */
}

.user-info-nav {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s ease;
    margin: 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-avatar {
    width: 30px;
    height: 30px;
    border: 2px solid #e83539;
    /* Bordo rosso */
    border-radius: 50%;
    /* Cerchio perfetto */
    background-color: #ffffff34;
    color: white;
    text-align: center;
    /* Colore di sfondo (può essere sostituito con l'immagine dell'avatar) */
}

.user-name {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    color: white;
}

.dropdown-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #333892;
    /* Blu coerente con l'app */
    color: white;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    min-width: 150px;
    display: flex;
    flex-direction: column;
}

.menu-item {
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    color: white;
    padding: 10px;
    transition: background-color 0.3s ease;
}

.menu-item:hover {
    background-color: #1c1f60;
    /* Blu più scuro per hover */
    border-radius: 4px;
}

.loading-dots {
    display: inline-flex;
    gap: 2px;
}

.loading-dots span {
    animation: blink 1.4s infinite;
}

.loading-dots span:nth-child(2) {
    animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {

    0%,
    100% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
    }
}

.toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 0.9rem;
}

:deep(.dp__input) {
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
    color: white;
    height: 60px;
    border-radius: 0;
}

:deep(.dp__input::placeholder) {
    color: rgba(255, 255, 255, 0.6);
}

:deep(.dp__input:focus) {
    border-color: #e83539;
    box-shadow: 0 0 0 0.25rem rgba(232, 53, 57, 0.25);
}

:deep(.dp__main) {
    font-family: 'Montserrat', sans-serif;
}

:deep(.dp__active) {
    background-color: #e83539 !important;
}
</style>