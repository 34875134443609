<template>
    <div class="registration-page text-white" style="background-color: #292D7D; min-height: 100vh; padding: 20px;">
        <div class="container">
            <img src="@/assets/logo.png" alt="Logo" class="logo">
            <h1 class="mb-4 fw-bold">Registrazione</h1>
            <router-link to="/login">
                <div class="btn white-container">
                    Hai già un account? Accedi
                </div>
            </router-link>
            <form @submit.prevent="register">
                <div class="row">
                    <div class="col-md-6">
                        <h4>Informazioni Personali</h4>
                        <div class="form-group">
                            <input v-model="user.firstName" type="text" placeholder="Nome" class="form-control" required />
                        </div>
                        <div class="form-group">
                            <input v-model="user.lastName" type="text" placeholder="Cognome" class="form-control" required />
                        </div>
                        <div class="form-group">
                            <Datepicker 
                                v-model="user.dateOfBirth" 
                                :format="format" 
                                :placeholder="'Data di nascita'"
                                :enable-time-picker="false"
                                :auto-apply="true"
                                :teleport="true"
                                input-class-name="form-control"
                                :preview-format="previewFormat"
                                @update:model-value="updateDateOfBirth"
                            >
                                <template #action-select>
                                    <span class="action-select">Seleziona</span>
                                </template>
                            </Datepicker>
                        </div>
                        <div class="form-group" style="margin-top:15px;">
                            <input v-model="user.placeOfBirth" type="text" placeholder="Luogo di nascita" class="form-control" required />
                        </div>
                        <div class="form-group">
                            <input v-model="user.taxCode" type="text" placeholder="Codice Fiscale" class="form-control" required />
                        </div>
                        <div class="form-group">
                            <GenderSelect v-model="user.gender"></GenderSelect>
                        </div>
                    </div>
                    <div class="col-md-6 mt-4 mt-sm-0">
                        <h4>Recapiti</h4>
                        <div class="form-group">
                            <input v-model="user.address" type="text" placeholder="Indirizzo" class="form-control" required />
                        </div>
                        <div class="form-group">
                            <input v-model="user.city" type="text" placeholder="Città" class="form-control" required />
                        </div>
                        <div class="form-group">
                            <input v-model="user.postalCode" type="text" placeholder="CAP" class="form-control" required />
                        </div>
                        <div class="form-group">
                            <input v-model="user.province" type="text" placeholder="Provincia" class="form-control" required />
                        </div>
                        <div class="form-group">
                            <input v-model="user.phoneNumber" type="text" placeholder="Telefono" class="form-control" />
                        </div>
                    </div>
                </div>
                <h4 class="mt-4">Crea un account</h4>
                <div class="form-group">
                    <input v-model="user.email" type="email" placeholder="Email" class="form-control" @blur="checkEmailExists" :class="{ 'is-invalid': emailError }" required />
                    <span v-if="emailError" class="text-danger">{{ emailError }}</span>
                </div>
                <div class="form-group position-relative">
                    <input 
                        v-model="user.password" 
                        :type="showPassword ? 'text' : 'password'" 
                        placeholder="Password" 
                        class="form-control" 
                        @blur="checkPassword" 
                        :class="{ 'is-invalid': passwordError }" 
                        required 
                    />
                    <span v-if="passwordError" class="text-danger">{{ passwordError }}</span>
                    <button type="button" class="toggle-password" @click="togglePasswordVisibility">
                        {{ showPassword ? 'Nascondi' : 'Mostra' }}
                    </button>
                </div>
                <p class="terms text-center">
                    Accetto i termini e le <a class="text-white" href="https://elitefitness.it/condizioni-generali-contratto">condizioni generali di contratto</a>.<br>
                    Confermo di aver preso visione dell'informativa relativa al <a class="text-white" href="https://elitefitness.it/diritto-di-recesso">diritto di
                        recesso</a> e dell'informativa sulla <a class="text-white" href="https://elitefitness.it/privacy">Privacy</a>.
                </p>
                <div class="text-center mt-4">
                    <button 
                        type="submit" 
                        class="btn btn-custom" 
                        :disabled="isLoading"
                        :class="{ 'loading': isLoading }"
                    >
                        <span v-if="!isLoading">Registrati</span>
                        <span v-else>
                            <span class="loading-dots">
                                <span>.</span>
                                <span>.</span>
                                <span>.</span>
                            </span>
                        </span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import GenderSelect from '@/components/GenderSelect.vue';

export default defineComponent({
    components: {
        GenderSelect,
        Datepicker
    },
    data() {
        return {
            user: {
                email: '',
                password: '',
                firstName: '',
                lastName: '',
                dateOfBirth: null,
                placeOfBirth: '',
                taxCode: '',
                gender: '',
                address: '',
                city: '',
                postalCode: '',
                province: '',
                phoneNumber: '',
                mobileNumber: ''
            },
            emailError: null,
            passwordError: null,
            showPassword: false, // Stato della visibilità della password
            isLoading: false, // Stato di caricamento
            format: 'dd/MM/yyyy',
            previewFormat: (date) => {
                if (!date) return ''
                const day = date.getDate().toString().padStart(2, '0')
                const month = (date.getMonth() + 1).toString().padStart(2, '0')
                const year = date.getFullYear()
                return `${day}/${month}/${year}`
            }
        };
    },
    methods: {
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        checkPassword() {
            if (!this.validatePassword(this.user.password)) {
                this.passwordError = "La password deve essere lunga tra 8 e 20 caratteri e contenere almeno un numero, una lettera maiuscola, una lettera minuscola e nessuno spazio.";
            } else {
                this.passwordError = null;
            }
        },
        validatePassword(password) {
            const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
            return passwordPattern.test(password);
        },
        async checkEmailExists() {
            try {
                if (!this.user.email) {
                    this.emailError = 'Inserisci un\'email valida.';
                    return;
                }

                const response = await fetch(`https://elitefitness.utopialabs.it/api/users/exists?email=${encodeURIComponent(this.user.email)}`);

                if (!response.ok) {
                    throw new Error('Errore nella verifica dell\'email');
                }

                const result = await response.json();

                if (result.exists) {
                    this.emailError = 'Questa email è già registrata.';
                } else {
                    this.emailError = null;
                }
            } catch (error) {
                console.error('Errore nella verifica dell\'email:', error);
                this.emailError = 'Impossibile verificare l\'email. Riprova più tardi.';
            }
        },
        async register() {
            if (this.emailError) {
                alert("L'email non è valida o è già in uso.");
                return;
            }
            if (this.passwordError){
                alert("La password non soddisfa i requisiti minimi di sicurezza.");
                return;
            }

            this.isLoading = true; // Attiva il caricamento
            try {
                const response = await fetch('https://elitefitness.utopialabs.it/api/auth/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.user)
                });

                if (!response.ok) {
                    throw new Error('Errore durante la registrazione');
                }

                const result = await response.json();
                console.log('Registrazione completata:', result);

                this.$router.push('/registration-complete');
            } catch (error) {
                console.error('Errore durante la registrazione:', error);
                alert('Si è verificato un errore durante la registrazione. Riprova più tardi.');
            } finally {
                this.isLoading = false; // Disattiva il caricamento
            }
        },
        updateDateOfBirth(date) {
            console.log('Data di nascita aggiornata:', date)
        }
    }
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.logo {
    max-width: 50px;  
    margin-bottom: 20px;
}

body {
    font-family: 'Montserrat', sans-serif;
}

h1, h4 {
    font-family: 'Montserrat', sans-serif;
    color: white;
}

.form-control, .form-select {
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
    color: white;
    margin-bottom: 15px;
    height: 60px;
    padding: 0 10px;
    border-radius: 0;
}

.form-control::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.btn-custom {
    font-family: 'Montserrat', sans-serif;
    background-color: #e83539;
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 0;
    font-size: 1.2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-custom.loading {
    background-color: #cc2e31; /* Colore più chiaro durante il caricamento */
    cursor: not-allowed;
}

.btn-custom:disabled {
    cursor: not-allowed;
    background-color: #cc2e31;
}

.loading-dots {
    display: inline-flex;
    gap: 2px;
}

.loading-dots span {
    animation: blink 1.4s infinite;
}

.loading-dots span:nth-child(2) {
    animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {
    0%, 100% {
        opacity: 0.3;
    }
    50% {
        opacity: 1;
    }
}

.text-danger {
    color: #ff4d4f;
}

.white-container{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.207);
    border-radius: 0px;
    color: #ffffff;
    padding: 10px;
    margin-bottom: 25px;
}

.white-container:hover{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.352);
    border-radius: 0px;
    color: #ffffff;
    padding: 10px;
    margin-bottom: 25px;
}

.action-select {
    color: #e83539;
    font-weight: bold;
}

.toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 0.9rem;
}

:deep(.dp__input) {
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
    color: white;
    height: 60px;
    border-radius: 0;
}

:deep(.dp__input::placeholder) {
    color: rgba(255, 255, 255, 0.6);
}

:deep(.dp__input:focus) {
    border-color: #e83539;
    box-shadow: 0 0 0 0.25rem rgba(232, 53, 57, 0.25);
}

:deep(.dp__main) {
    font-family: 'Montserrat', sans-serif;
}

:deep(.dp__active) {
    background-color: #e83539 !important;
}
</style>
